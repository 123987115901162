.nguoi-dung-body {
  .ant-modal-content {
    background-color: #fff !important;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4) !important;
  }

  .ant-input,
  .ant-picker {
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color 0.2s ease, background-color 0.2s ease;
    padding: 0.75rem;
  }
  .ant-input-number {
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color 0.2s ease, background-color 0.2s ease;
    padding: 0.5rem;
    height: 43.5px;
  }

  .ant-input-affix-wrapper > .ant-input:not(textarea) {
    padding: 0.25rem;
  }

  .ant-input-affix-wrapper {
    background-color: #f5f8fa;
  }
  .ant-select {
    .ant-select-selector {
      background-color: #f5f8fa;
      border-color: #f5f8fa;
      color: #5e6278;
      height: 43.5px;
      padding: 0.5rem;
    }
  }
}
